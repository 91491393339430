<template>
  <div class="text-bb-text-default space-y-6 py-6 container-height">
    <div class="space-y-8 px-8">
      <div class="space-y-2">
        <h4 class="h4">What’s your goal?</h4>
        <p class="text-sm font-normal">Choose your monitoring goal, and we'll tailor the experience to match.</p>
      </div>
    </div>
    <div class="px-8 space-y-5">
      <div
        v-for="option in monitoringOptions"
        :key="option.value"
        class="relative p-3 flex items-start gap-6 w-full border rounded-2xl duration-300 ease-linear cursor-pointer"
        :class="{
          'border-neutral-100': hoveredOption !== option.value,
          'blue-border':
            option.value === 'branded-monitoring' &&
            (hoveredOption === option.value || selectedMonitoring === option.value),
          'red-border':
            option.value === 'generic-monitoring' &&
            (hoveredOption === option.value || selectedMonitoring === option.value),
        }"
        style="max-width: 550px"
        @mouseenter="hoveredOption = option.value"
        @mouseleave="hoveredOption = null"
        @click="selectMonitoringType(option.value)"
      >
        <div
          class="h-25 w-25 rounded-lg pl-4 pt-8 pb-0.5 pr-0.5"
          :class="{
            'blue-bg-1': option.value === 'branded-monitoring',
            'red-bg-1': option.value === 'generic-monitoring',
          }"
        >
          <div
            class="h-full w-full rounded-tl-2xl rounded-br-md"
            :class="{
              'blue-gradient': option.value === 'branded-monitoring',
              'red-gradient': option.value === 'generic-monitoring',
            }"
          >
            <div class="w-full flex gap-2 items-center pt-3 pl-3 pr-0.5">
              <div
                class="h-6 w-6 rounded-md"
                :class="{
                  'blue-bg-3 blue-icon-color': option.value === 'branded-monitoring',
                  'red-bg-3 red-icon-color': option.value === 'generic-monitoring',
                }"
              >
                <component
                  :is="option.icon"
                  class="my-auto mx-auto h-6"
                />
              </div>
              <div
                class="h-6 w-9 rounded-l-md"
                :class="{
                  'blue-bg-2': option.value === 'branded-monitoring',
                  'red-bg-2': option.value === 'generic-monitoring',
                }"
              />
            </div>
            <div class="w-full flex items-center justify-end pr-0.5 pt-0.5">
              <div
                class="h-3 w-9 rounded-l-sm"
                :class="{
                  'blue-bg-4': option.value === 'branded-monitoring',
                  'red-bg-4': option.value === 'generic-monitoring',
                }"
              ></div>
            </div>
            <div class="w-full flex items-center justify-end pr-0.5 pt-0.5">
              <div
                class="h-2 w-9 rounded-l-xs"
                :class="{
                  'blue-bg-4': option.value === 'branded-monitoring',
                  'red-bg-4': option.value === 'generic-monitoring',
                }"
              ></div>
            </div>
          </div>
        </div>
        <div class="space-y-1">
          <div
            class="py-1 px-2 rounded-md w-fit text-xs font-bold"
            :class="{
              'blue-text-color blue-bg-3': option.value === 'branded-monitoring',
              'red-text-color red-bg-3': option.value === 'generic-monitoring',
            }"
          >
            <p>{{ option.label }}</p>
          </div>
          <div>
            <h5 class="h5">{{ option.title }}</h5>
            <p class="text-xs font-normal">{{ option.description }}</p>
          </div>
        </div>
        <div class="absolute top-0 right-0 p-2">
          <radio-button-v2
            :is-selected="selectedMonitoring === option.value"
            :is-hovered="hoveredOption === option.value"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/views/site/search/competitor_monitoring_v2/styles/style.css'

import IcGuard from '@/components/icon/brightbid/IcGuard.vue'
import IcGeneric from '@/components/icon/brightbid/IcGeneric.vue'

import RadioButtonV2 from '@/components/input/brightbid/RadioButtonV2.vue'

export default {
  name: 'BrandedOrGenericMonitoring',
  components: {
    IcGuard,
    IcGeneric,
    RadioButtonV2,
  },
  props: {
    savedMonitoringType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hoveredOption: null,
      selectedMonitoring: null,
      monitoringOptions: [
        {
          label: 'Brand',
          value: 'branded-monitoring',
          icon: 'ic-guard',
          title: 'Monitor my Brand terms ',
          description:
            'Competition monitoring tailored  for your brand campaign. Infringement detection and Smart activation.',
        },
        {
          label: 'Generic',
          value: 'generic-monitoring',
          icon: 'ic-generic',
          title: 'Monitor generic terms & gain insights',
          description:
            "Discover new opportunities by tracking your competitors' ads. Gain insights from analyzing their strategies to stay ahead of the competition.",
        },
      ],
    }
  },
  mounted() {
    if (this.savedMonitoringType) this.selectedMonitoring = this.savedMonitoringType
  },
  methods: {
    selectMonitoringType(value) {
      this.selectedMonitoring = value
      this.$emit('select-monitoring', this.selectedMonitoring)
    },
  },
}
</script>

<style scoped lang="scss">
.container-height {
  min-height: calc(100vh - 410px);
}
</style>
