<template>
  <div
    class="duration-200 ease-linear cursor-pointer"
    :class="chipStyle"
    @mouseenter="hoveredChip = chip.value"
    @mouseleave="hoveredChip = null"
    @click="$emit('select-chip', chip)"
  >
    <!-- Left icon (only visible on hover or when selected) -->
    <div
      v-if="showLeftIconOnHover"
      class="duration-200 ease-linear overflow-hidden"
      :class="leftIconClass"
    >
      <slot name="left-icon"></slot>
    </div>

    <!-- Label -->
    <p>{{ chip.label }}</p>

    <!-- Right icon (always visible unless hideRightIcon is set to true) -->
    <div
      v-if="!hideRightIcon"
      class="duration-200 ease-linear overflow-hidden flex items-center justify-center h-5 w-5 mr-1"
      @click="clickIcon"
    >
      <slot name="right-icon"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChipTag',
  props: {
    chipStyle: {
      type: String,
      default: 'bg-white rounded-md pl-2 flex gap-2 items-center w-fit',
    },
    hoverStyle: {
      type: String,
      default: 'bg-gray-200',
    },
    chip: {
      type: Object,
      required: true,
    },
    showLeftIconOnHover: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    hideRightIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hoveredChip: null,
    }
  },
  computed: {
    leftIconClass() {
      return this.hoveredChip === this.chip.value || this.expanded || !this.expandable
        ? `${this.hoverStyle} opacity-100 translate-x-0`
        : 'w-0 opacity-0 -translate-x-1/2'
    },
  },
  methods: {
    clickIcon(event) {
      event.stopPropagation()
      this.$emit('click-right-icon', this.chip)
    },
  },
}
</script>
