<template>
  <tbody>
    <tr
      v-for="keyword in computedKeywords"
      :key="keyword.id"
      class="border-b capitalize"
      :class="{ 'text-bb-disabled-gray': keyword.isSelected }"
    >
      <td
        v-if="!keyword.isNew"
        class="p-4 max-w-30 overflow-hidden whitespace-nowrap truncate"
      >
        {{ keyword.keyword_text }}{{ keyword.keyword }}
      </td>
      <td
        v-if="!keyword.isNew"
        class="p-4 px-8"
      >
        {{ keyword.search_volume || 'N/A' }}
      </td>
      <td
        v-if="!keyword.isNew"
        class="p-4 px-8"
      >
        {{ keyword.clicks || 'N/A' }}
      </td>
      <td v-if="!keyword.isNew">
        <MergeButtonRound
          button-type="tertiary"
          :disabled="keyword.isSelected"
          class="h-6 w-6 px-7 mx-3"
          @click="addKeyword(keyword)"
        >
          {{ keyword.isSelected ? 'Added' : 'Add' }}
        </MergeButtonRound>
      </td>
      <td
        v-if="keyword.isNew && keyword === computedKeywords[0]"
        colspan="4"
        class="h-55 px-8 pb-20"
      >
        <p class="font-medium">"{{ keyword.keyword_text }}{{ keyword.keyword }}"</p>
        <div class="flex flex-row gap-x-1 justify-between normal-case">
          <p>No branded term in your account match this keyword. <br />Add anyway?</p>
          <MergeButtonRound
            button-type="tertiary"
            :disabled="keyword.isSelected"
            class="h-6 w-6 px-7 mx-3"
            @click="addKeyword(keyword)"
          >
            {{ keyword.isSelected ? 'Added' : 'Add' }}
          </MergeButtonRound>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'

export default {
  name: 'TopKeywordsTableRows',
  components: {
    MergeButtonRound,
  },
  props: {
    keywords: {
      type: Array,
      required: true,
    },
    selectedKeywords: {
      type: Array,
      required: true,
    },
  },
  computed: {
    computedKeywords() {
      return this.keywords.map(keyword => ({
        ...keyword,
        isSelected: this.isKeywordSelected(keyword),
      }))
    },
  },
  methods: {
    addKeyword(keyword) {
      this.$emit('add-keyword', keyword)
    },
    isKeywordSelected(keyword) {
      return this.selectedKeywords.some(selected => selected.value === keyword.id)
    },
  },
}
</script>

<style scoped lang="scss"></style>
