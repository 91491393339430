<template>
  <ul>
    <li
      v-for="keyword in keywords"
      :key="keyword.value"
    >
      <div class="flex flex-row justify-between w-full border-b py-3">
        <div class="flex flex-row items-center gap-x-5 flex-grow min-h-12">
          <div class="max-w-64 capitalize truncate">{{ keyword.label }}</div>
          <transition name="fade">
            <div
              v-if="keyword.selected"
              class="bg-bb-blue-pale rounded-md p-0.5"
            >
              <ic-guard class="w-5 h-5 text-bb-blue" />
            </div>
          </transition>
        </div>
        <slider-switch
          :id="keyword.value"
          type="keyword"
          :status="keyword.selected ? 'ENABLED' : 'DISABLED'"
          @update-status="updateKeywordStatus(keyword)"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import SliderSwitch from '@/components/input/brightbid/SliderSwitch.vue'
import IcGuard from '@/components/icon/brightbid/IcGuard.vue'

export default {
  name: 'KeywordList',
  components: { SliderSwitch, IcGuard },
  props: {
    keywords: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateKeywordStatus(keyword) {
      this.$emit('update-status', keyword)
    },
  },
}
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
