<template>
  <div class="px-8 py-6 space-y-6 max-w-150 w-full container-height">
    <div class="flex items-center gap-3">
      <h4 class="h4">Detect Infringement</h4>
      <ic-info-solid class="h-4 w-4 text-bb-disabled-gray" />
    </div>
    <div class="space-y-8">
      <div>
        <h5 class="h5">Select the brand terms that should trigger infringement alerts</h5>
        <p class="text-bb-neutral-gray pt-1">Use only your trademarked brand terms, exclude any generic terms</p>
      </div>
      <div class="max-w-110">
        <keyword-list
          :keywords="computedActivateKeywords"
          @update-status="selectTrademark"
        />
      </div>
    </div>
  </div>
</template>

<script>
import KeywordList from '@/views/site/search/competitor_monitoring_v2/components/KeywordList.vue'
import IcInfoSolid from '@/components/icon/brightbid/ic-info-solid.vue'

export default {
  name: 'SetupInfringementsDetector',
  components: { KeywordList, IcInfoSolid },
  props: {
    trademarks: {
      type: Array,
      default: () => [],
    },
    selectedTrademarks: {
      type: Array,
      default: () => [],
    },
    withBrandedCampaign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      trademark: '',
      duplicateTrademark: false,
    }
  },
  computed: {
    computedActivateKeywords() {
      return this.trademarks.map(item => {
        item.selected = this.selectedTrademarks.includes(item)
        return item
      })
    },
  },
  methods: {
    selectTrademark(keyword) {
      this.$emit('select-trademark', keyword)
    },
  },
}
</script>

<style scoped lang="scss">
.container-height {
  min-height: calc(100vh - 410px);
}
</style>
